<template>
  <div>
    <v-card elevation="0">
      <v-card-title>{{ $t("ui_select_process") }}</v-card-title>
      <v-card-text>
        <v-select
          v-model="selectedProcess"
          :items="processes"
          :label="$t('ui_process')"
          item-text="name"
          return-object
          dense
        />
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="accent"
          elevation="1"
          :disabled="disabled"
          @click="onSubmit"
          >{{ $t("ui_next") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      selectedProcess: {},
    };
  },
  computed: {
    ...mapState("core$common", ["processes"]),
    disabled() {
      return !this.selectedProcess.id;
    },
  },
  methods: {
    onSubmit() {
      if (this.disabled) return;

      this.$router.push({
        name: "TaskAddDetails",
        params: {
          processKey: this.selectedProcess.key,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
